/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import TaskWindow from './pages/taskWindow';

import axios from 'axios';

import General from './api/general';

import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';

import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
let telephone = new WebSocket('ws://127.0.0.1:8555/api');

let timer;
telephone.onerror = function (event) {
  console.log('--------error--------');
};
telephone.onclose = function (event) {
  telephone = new WebSocket('ws://127.0.0.1:8555/api');
  clearInterval(timer);
};
//与WebSocket建立连接
telephone.onopen = function (event) {
  timer = setInterval(HeartBeatCheck, 10000);
  //来电弹屏
  telephone.send('{"command":"InCallPoPup","arguments":{"content":"0"}}');
  telephone.send('{"command":"SetInHideNumber","arguments":{"content":"0"}}');
  telephone.send('{"command":"UpdateRecordPath","arguments":{"content":"C:\\record"}}');
  telephone.send('{"command":"OutCallPoPup","arguments":{"content":"0"}}');
  telephone.send('{"command":"HideNumber"}');
};

function HeartBeatCheck() {
  telephone.send('HeartBeatData');
}
export default function App() {
  const [locations, setLocations] = useState({ pathname: '' });

  useEffect(() => {
    General.getUserId().then(res=>{
      localStorage.setItem('user_id',res)
    })
    setLocations(window.location);
  }, []);

  useEffect(() => {

  }, []);

  return (
    <>
      <ConfigProvider locale={zhCN}>
        <TaskWindow TelePhone={telephone} />
      </ConfigProvider>
    </>
  );
}
