import React from 'react';
import ReactDOM from 'react-dom';

// import 'antd/dist/antd.css';
import './assets/css/style.css';
import 'antd-multi-cascader/dist/index.css';

import App from './App.js';

ReactDOM.render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,

  document.getElementById('callTask'),
);
