import { Get, Post } from '../axios';

const General = {
  //token
  getToken() {
    let result = Get('/getCsrfToken');
    return result;
  },
  // 获取通用数据
  getInfo(params) {
    let result = Get('/getBaseBdInfo', params);
    return result;
  },
  // 获取跟进记录
  getLog(params) {
    let result = Post('/getLogs', params);
    return result;
  },
  // 详情
  getDetail(params) {
    let result = Get(`/bdxs_center_show/${params.id}?crm_user_id=${params.crm_user_id}`);
    return result;
  },
  // 编辑用户标签
  editTag(params) {
    let result = Post('/edit_tags', params);
    return result;
  },
  // 线索中心批量增加用户标签
  editMoreTag(params) {
    let result = Post('/bdAddTags', params);
    return result;
  },
  // 加入黑名单
  sendBlack(params) {
    let result = Post('/addBlackList', params);
    return result;
  },
  // 变更跟进人
  ChangUserIdAjax(params) {
    let result = Post(`/ChangUserIdAjax`, params);
    return result;
  },
  // 添加联系人
  addConnect(params) {
    let result = Post(`/addLxr`, params);
    return result;
  },
  // 变更销售阶段
  changeStatus(params) {
    let result = Post(`/allSaleRankChangUp`, params);
    return result;
  },
  // 添加跟进记录
  addFollowLog(params) {
    let result = Post(`/addFollowLog`, params);
    return result;
  },
  // 是否为投放线索
  touFangIssue(params) {
    let result = Get('getIsTf/' + params);
    return result;
  },
  // 移交(批量|单独)
  HandOver(params) {
    let result = Post(`/YiJiaoAjax`, params);
    return result;
  },
  // 创建拨打记录
  createCallLog(params) {
    let result = Post('/bdCallPhone', params);
    return result;
  },
  // 分配(批量分配|分配)
  batchAllocation(params) {
    let result = Post(`/fenPeiXsAjax`, params);
    return result;
  },
  // 领取 (批量领取|领取)
  batchGet(params) {
    let result = Post(`/LingQuAjax`, params);
    return result;
  },
  //   是否有效
  changeValid(params) {
    let result = Post('/bdxsIsValid', params);
    return result;
  },
  // 获取部门
  getDept() {
    let result = Get('/bdDeptsTree');
    return result;
  },
  //   编辑线索
  clueEdit(params) {
    let result = Post(`/bdxs_center_edit`, params);
    return result;
  },
  // 加入客保
  addPrivate(params) {
    let result = Post('/addPrivateCrm', params);
    return result;
  },
  checkCall(params) {
    let result = Post('/rxtCall', params);
    return result;
  },
  // ------------------
  delTask(params) {
    let result = Post('/delAutoQueue', params);
    return result;
  },
  getUserId(params) {
    let result = Get('/getAuthId', params);
    return result;
  },
  getDeptSecondConfig(params) {
    let result = Post('/getSelfDeptSecond', params);
    return result;
  },
  testPhone(params) {
    let result = Post('/verifyPhoneSeal', params);
    return result;
  },
};

export default General;
