/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import Maximize from './windowMaximize';

// 当前登陆人ID
const userID = localStorage.getItem('user_id');

let baseUrl = window.location;
// baseUrl.hostname = (baseUrl.hostname == 'localhost:3000')?'pre.niuniubang.com':baseUrl.hostname;
let taskWebSocket = new WebSocket(`ws://${baseUrl.hostname == 'localhost'?'pre.niuniubang.com':baseUrl.hostname}:${((baseUrl.hostname).indexOf('pre') == -1 && (baseUrl.hostname).indexOf('localhost') == -1)?'9501':'9502'}?page=home&token=123456`);
let timer;
taskWebSocket.onerror = function (event) {
  console.log('--------task error--------');
};
taskWebSocket.onclose = function (event) {
  console.log(event);
  taskWebSocket = new WebSocket(`ws://${baseUrl.hostname}:9501?page=home&token=123456`);
  clearInterval(timer);
};

/**
 *
 * @param {status} event
 * 1 未拨打
 * 2 拨打中
 * 3 暂停
 *
 */
/**
 *
 * @param {setKeyValue} autoMaticCall
 *  autoMaticCall+Num+user_id 发送当前 任务条数
 *  autoMaticCall+Status+user_id 发送当前状态
 * @param {getKeyValue} autoMaticCall
 *  autoMaticCall+user_id 获取当前任务列表
 *  autoMaticCall+Num+user_id 获取当前任务条数
 *  autoMaticCall+Status+user_id 获取当前状态
 */
//与WebSocket建立连接
taskWebSocket.onopen = function (event) {
  timer = setInterval(HeartBeatCheck, 30000);

  taskWebSocket.send(`{"method":"getKeyValue","type":"getList","arguments":{"key":"autoMaticCall${userID}"}}`);
  taskWebSocket.send(`{"method":"getKeyValue","type":"getNum","arguments":{"key":"autoMaticCallNum${userID}"}}`);
  taskWebSocket.send(`{"method":"getKeyValue","type":"getStatus","arguments":{"key":"autoMaticCallStatus${userID}"}}`);
};

function HeartBeatCheck() {
  taskWebSocket.send('HeartBeatData');
 
}
const TaskWindow = (props) => {
  const { TelePhone } = props;
  // 鉴别创建入口 呼叫中心/线索管理
  const [createType, setCreateType] = useState(localStorage.getItem('create_type'));
  // 基本通用数据
  const [baseInfo, setBaseInfo] = useState(JSON.parse(localStorage.getItem('base_info')));
  useEffect(() => {
    setInterval(() => {
      if (createType == undefined || createType == null) {
        setCreateType(localStorage.getItem('create_type'));
      }
    }, 5000);
  }, []);

  useEffect(() => {
    setInterval(() => {
      if (baseInfo == undefined || baseInfo == null) {
        setBaseInfo(JSON.parse(localStorage.getItem('base_info')));
      }
    }, 5000);
  }, [baseInfo]);

  return (
    <div css={{ padding: 0, margin: 0 }}>
      <Maximize TaskList={taskWebSocket} TelePhone={TelePhone} BaseInfo={baseInfo} TaskCreateType={createType} />
    </div>
  );
};

export default TaskWindow;
