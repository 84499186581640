/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* @jsxImportSource @emotion/react */

import React, { useEffect, useState, useRef } from 'react';
import { Table, Row, Col, Button, Form, Select, Input, message, Modal } from 'antd';
import General from '../../../api/general';

const Maximize = (props) => {
  const [isAnswerData, setIsAnswerData] = useState()
  const { TelePhone, TaskList, BaseInfo, TaskCreateType } = props;
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 103,
    },
    {
      title: '客户名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 166,
      render: (text, record, index) => {
        return (
          <p css={{ display: 'block' }} title={text}>
            {text}
          </p>
        );
      },
    },
    {
      title: '联系人',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 103,
      render: (text, record, index) => {
        return (
          <p css={{ display: 'block' }} title={text}>
            {text}
          </p>
        );
      },
    },
    {
      title: '所属城市',
      dataIndex: 'place',
      key: 'place',
      align: 'center',
      width: 103,
    },
    {
      title: '风险',
      dataIndex: 'forbid',
      key: 'forbid',
      align: 'center',
      width: 103,
    },
    {
      title: '用户标签',
      dataIndex: 'tag_id',
      key: 'tag_id',
      align: 'center',
      width: 103,
    },
  ];
  const [minORmax, setMinOrMax] = useState('min');
  const [tableData, setTableData] = useState({ rows: [], total: 0 });
  // 任务数
  const [taskNum, setTaskNum] = useState(0);
  // 进度数
  const [progressEnd, setProgressEnd] = useState(false);
  const [taskStatus, setTaskStatus] = useState(0);
  const [callType, setCallType] = useState('');
  const [seconds, setSeconds] = useState(0);
  const userID = localStorage.getItem('user_id');

  const [form] = Form.useForm();
  const [currentTask, setCurrentTask] = useState();
  const [cancelLoading, setCancelLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);

  const [formData, setFormData] = useState({});
  const [selfDeptSecond, setSelfDeptSecond] = useState();
  const [isConnect, setIsConnect] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [checkPhone, setCheckPhone] = useState(false);
  const [clickBg, setClickBg] = useState(false);
  let callTimer = useRef();
  useEffect(() => {
    General.getDeptSecondConfig().then((res) => {
      setSelfDeptSecond(res);
    });
    if (localStorage.getItem('isConnect') !== undefined && localStorage.getItem('isConnect') !== null) {
      setIsConnect(localStorage.getItem('isConnect'));
    }
  }, []);

  TaskList.onmessage = function (event) {
    const BACK_INFO = JSON.parse(event.data);
    if (BACK_INFO.fd !== null && BACK_INFO.fd !== undefined) {
      if (window.location.pathname.indexOf('bdxs_customer') !== -1) {
        localStorage.setItem('task_fd', JSON.stringify({ url: 'bdxs_customer', fd: BACK_INFO.fd }));
      }
      if (window.location.pathname.indexOf('callCenterView') !== -1) {
        localStorage.setItem('task_fd', JSON.stringify({ url: 'callCenterView', fd: BACK_INFO.fd }));
      }
    }
    /**
     * invoke_method
     * getList   获取列表
     * getNum    获取当前任务进度
     * getStatus 获取当前任务状态
     */
    switch (BACK_INFO.invoke_method) {
      case 'getList':
        if (BACK_INFO.data) {
          BACK_INFO.data.total = parseInt(BACK_INFO.data.total);
          setTableData({ rows: BACK_INFO.data.rows, total: BACK_INFO.data.total });
          localStorage.setItem('taskData', JSON.stringify(BACK_INFO.data));
        } else {
          if (BACK_INFO.rows !== undefined) {
            setTableData({ rows: BACK_INFO.rows, total: parseInt(BACK_INFO.total) });
            setTaskStatus(BACK_INFO.task_status);
            setTaskNum(BACK_INFO.task_num);
            localStorage.setItem('taskData', JSON.stringify({ rows: BACK_INFO.rows, total: parseInt(BACK_INFO.total) }));
          }
          if (BACK_INFO.data == null) {
            localStorage.removeItem('taskData');
          }
        }
        break;
      case 'getNum':
        if (BACK_INFO.data !== null && BACK_INFO.data !== '') {
          setTaskNum(BACK_INFO.data);
        }
        break;
      case 'getStatus':
        if (BACK_INFO.data !== null && BACK_INFO.data !== undefined && BACK_INFO.data !== '') {
          setTaskStatus(BACK_INFO.data);
          localStorage.setItem('task_status', BACK_INFO.data);
        } else {
          localStorage.removeItem('task_status');
          localStorage.removeItem('create_type');
        }
        break;

      default:
        break;
    }
  };
  // 变更任务进度
  const changeTaskProgress = (num) => {
    TaskList.send(`{"method":"setKeyValue","arguments":{"key":"autoMaticCallNum${userID}","value":"${num}"}}`);
  };
  // 变更任务状态
  const changeTaskStatus = (status) => {
    TaskList.send(`{"method":"setKeyValue","arguments":{"key":"autoMaticCallStatus${userID}","value":"${status}"}}`);
  };
  const startTask = () => {
    if (minORmax == 'min') {
      setMinOrMax('max');
    }
    setClickBg(false);
    setCurrentTask(null);
    setProgressEnd(false);
    check();

    changeTaskStatus(2);
    setTaskStatus(2);
  };
  const pauseTask = () => {
    setTaskStatus(3);
    changeTaskStatus(3);
  };
  const check = (num) => {
    console.log('第', num ? num : taskNum, '次校验');

    let checkNum = num ? num : taskNum;

    if (checkNum > tableData.total - 1) {
      changeTaskProgress(checkNum);
      changeTaskStatus(4);
      setTaskStatus(4);
      console.log('close');
    } else {
      General.checkCall({ phone: tableData.rows[checkNum].phone })
        .then((res) => {
          if (res.code == 200) {
            setCheckPhone(false);
            General.testPhone({ phone: tableData.rows[checkNum].phone }).then((resp) => {
              if (resp.code == 200) {
                message.success(res.msg);
                call(checkNum);
                setCheckPhone(false);
              } else {
                message.error(resp.msg);
                console.log('防封号检测未通过；');
                setTimeout(() => {
                  setCheckPhone(true);
                  nextProgress();
                  setNextLoading(false);
                }, 2000);
              }
            });
          } else {
            message.error(res.msg);
            console.log('风险号检测未通过；');
            setTimeout(() => {
              setCheckPhone(true);
              nextProgress();
              setNextLoading(false);
            }, 2000);
          }
        })
        .catch((err) => {
          message.error('登录已过期，请重新登录！');
          return false;
        });
    }
  };
  // 检查
  useEffect(() => {
    if (checkPhone) {
      if (progressEnd == false && taskStatus == 2) {
        setTimeout(() => {
          check(taskNum);
        }, 5000);
      }
    }
  }, [taskNum]);
  // 按钮状态
  useEffect(() => {
    localStorage.setItem('isConnect', isConnect);
  }, [isConnect]);

  // 呼叫
  const call = (num) => {
    let phone = num ? tableData.rows[num].phone : tableData.rows[taskNum].phone;
    TelePhone.send('{"command":"SetOutHideNumber_Multi","arguments":{"content":"1","devicename":"' + localStorage.getItem('devicename') + '"}}');
    TelePhone.send('{"command":"Dial_Multi","arguments":{"phone":"' + phone + '","devicename":"' + localStorage.getItem('devicename') + '"}}');
    localStorage.setItem('autoTask', 'true');
    localStorage.removeItem('singleCall');
    setIsConnect('waiting');
  };
  // 进度增加
  const nextProgress = () => {
    let nowNum = parseInt(taskNum);
    if (nowNum < tableData.total - 1) {
      changeTaskProgress(nowNum + 1);
      setTaskNum(nowNum + 1);
    } else {
      console.log('任务已完成', nowNum);
      clearInterval(callTimer.current);
      changeTaskProgress(nowNum);
      changeTaskStatus(4);
      setTaskStatus(4);
    }
  };
  TelePhone.onmessage = function (event) {
    let BACK_INFO = JSON.parse(event.data);

    //
    /* 通话状态
     * outgoing		//设备呼出
     * ringback		//对方振铃
     * incoming 		//收到来电
     * outconnected   //呼出接通
     * inconnected    //呼入接通
     * hangup		//挂断
     * idle
     */
    if (localStorage.getItem('autoTask') && localStorage.getItem('autoTask') == 'true') {

      //todo 设置电话拨打相关时间
      if (BACK_INFO?.type == 'CallRecord') {
        setIsAnswerData(BACK_INFO.dynamicdata)
      }


      if (BACK_INFO.data && BACK_INFO.data !== null) {
        if (BACK_INFO.data.invoke_command == 'Dial_Multi') {
          callTimer.current = setInterval(() => setSeconds((t) => t + 1), 1000);
        }
        // if (BACK_INFO.data.invoke_command == 'HangUp_Multi') {
        // }
        if (BACK_INFO.data.invoke_command == 'StopRecord_Multi') {
          console.log('接通挂断');
          hungUpFun();
        }
      }
      if (BACK_INFO.dynamicdata !== null && BACK_INFO.dynamicdata.realtimestate !== null) {
        switch (BACK_INFO.dynamicdata.realtimestate) {
          case 'outgoing':
            setNextLoading(false);
            break;
          case 'outconnected':
            setCallType('outconnected');
            setIsConnect('outconnected');

            TelePhone.send(`{"command":"StartRecord_Multi","arguments":{"devicename":"${localStorage.getItem('devicename')}","content":"record${tableData.rows[taskNum].id}.wav"}}`);
            break;
          case 'inconnected':
            setCallType('inconnected');

            TelePhone.send(`{"command":"StartRecord_Multi","arguments":{"devicename":"${localStorage.getItem('devicename')}","content":"record${tableData.rows[taskNum].id}.wav"}}`);
            break;
          case 'incoming':
            // TelePhone.send('{"command":"Answer"}');
            break;
          case 'hangup':
            if (callType !== '') {
              TelePhone.send(`{"command":"StopRecord_Multi","arguments":{"devicename":"${localStorage.getItem('devicename')}"}}`);
             
            } else {
              console.log('未接通挂断');
              hungUpFun();
            }
            break;
          default:
            break;
        }
      }
    }
  };
  const hungUpFun = () => {
    // 清除定时器
    clearInterval(callTimer.current);
    // 设置当前选择任务为空
    setCurrentTask(null);
    // 设置定时器秒数0
    setSeconds(0);

    count(taskNum);

    setIsConnect('hungUp');
    setClickBg(false);

    message.success('挂断成功！');
    setCheckPhone(true);

    nextProgress();

    form.resetFields();
  };
  // 超时自动挂断配置
  useEffect(() => {
    console.log('s,' + seconds);
    if (isConnect == 'waiting') {
      if (seconds > selfDeptSecond) {
        TelePhone.send(`{"command":"HangUp_Multi","arguments":{"devicename":"${localStorage.getItem('devicename')}"}}`);
      }
    } else if (isConnect == 'hungUp') {
      setIsConnect('waiting');
    }
  }, [seconds]);

  // 添加拨打次数
  const count = (num) => {
    if (seconds > 2) {
      if (callType !== '') {
        General.createCallLog({
          id: tableData.rows[num].crm_main_id,
          bada_status: 3,
          crm_user_id: tableData.rows[num].id,
        }).then((res) => {
          if (localStorage.getItem('create_type') == 'clueControl') {
            TelePhone.send(
              `{"command":"UploadFile","arguments":{"content":"1","url":"${window.location.origin}/bdCallPhoneVoice","file":"C:/record/record${
                tableData.rows[num].id
              }.wav","diykey":"id,type,phone,node,crm_user_id,crm_customers_main_id,is_answer,created_by,ccid,starttime,endtime,answertime","diyvalue":"${tableData.rows[num].id},${localStorage.getItem('create_type')},${
                tableData.rows[num].phone
              },2,${tableData.rows[num].id},${tableData.rows[num].crm_main_id},1,${JSON.parse(localStorage.getItem('base_info')).auth_user_id},${localStorage.getItem('ccid')},${isAnswerData.starttime},${isAnswerData.endtime},${isAnswerData.answertime}"}}`
            );
          }
          if (localStorage.getItem('create_type') == 'callCenter') {
            TelePhone.send(
              `{"command":"UploadFile","arguments":{"content":"1","url":"${window.location.origin}/bdCallPhoneVoice","file":"C:/record/record${
                tableData.rows[num].id
              }.wav","diykey":"id,type,phone,node,crm_user_id,crm_customers_main_id,is_answer,created_by,ccid,starttime,endtime,answertime","diyvalue":"${tableData.rows[num].id},${localStorage.getItem('create_type')},${
                tableData.rows[num].phone
              },5,${tableData.rows[num].id},${tableData.rows[num].crm_main_id},1,${JSON.parse(localStorage.getItem('base_info')).auth_user_id},${localStorage.getItem('ccid')},${isAnswerData.starttime},${isAnswerData.endtime},${isAnswerData.answertime}"}}`
            );
          }
          setCallType('');
        });
      } else {
        General.createCallLog({
          id: tableData.rows[taskNum].crm_main_id,
          bada_status: 2,
          crm_user_id: tableData.rows[taskNum].id,
        }).then((res) => {});
      }
    }
  };
  // 取消任务
  const cancelTask = () => {
    clearInterval(callTimer.current);
    setCancelLoading(true);
    setCurrentTask(null);
    General.delTask()
      .then((res) => {
        setProgressEnd(true);
        changeTaskStatus(0);
        changeTaskProgress(0);
        setCancelLoading(false);
        setTableData({ rows: [], total: '' });
        setTaskStatus(0);
        setTaskNum(0);
        setSeconds(0);
        localStorage.removeItem('create_type');
        localStorage.removeItem('task_status');
        localStorage.removeItem('taskData');
        localStorage.removeItem('autoTask');
        setMinOrMax('min');
        form.resetFields();
        setShowModal(false);
      })
      .catch((err) => {});
  };
  // 挂断并拨打下一个
  const callNext = () => {
    TelePhone.send(`{"command":"HangUp_Multi","arguments":{"devicename":"${localStorage.getItem('devicename')}"}}`);
    form.resetFields();
    setNextLoading(true);
    setCurrentTask(null);
  };
  // 挂断当前电话
  const callNow = () => {
    TelePhone.send(`{"command":"HangUp_Multi","arguments":{"devicename":"${localStorage.getItem('devicename')}"}}`);
    form.resetFields();
    setNextLoading(true);
    setCurrentTask(null);
  };
  // 保存跟进记录
  const saveFollowLog = () => {
    switch (TaskCreateType) {
      case 'callCenter':
        checkCallCenter();
        break;
      case 'clueControl':
        checkClueControl();
        break;
      default:
        break;
    }
  };
  // 线索管理
  const checkClueControl = () => {
    let obj = formData;
    if (currentTask) {
      obj.name = currentTask.name;
      obj.sub_phone = currentTask.sub_phone;
      obj.crm_customers_id = currentTask.crm_main_id;
      obj.crm_user_id = currentTask.id;
      obj.yixiang = 0;
    } else {
      obj.name = tableData.rows[taskNum].name;
      obj.sub_phone = tableData.rows[taskNum].sub_phone;
      obj.crm_customers_id = tableData.rows[taskNum].crm_main_id;
      obj.crm_user_id = tableData.rows[taskNum].id;
      obj.yixiang = 0;
    }

    if (!obj.content) {
      obj.content = '';
    }
    if (!obj.phone_status) {
      obj.phone_status = '';
    }
    if (!obj.sale_rank) {
      obj.sale_rank = '';
    }
    if (!obj.role_name) {
      obj.role_name = '';
    }
    General.addFollowLog(obj)
      .then((res) => {
        if (res.code == 200) {
          message.success('添加跟进记录成功');
          form.resetFields();
        } else {
          message.error(res.msg);
        }
      })
      .catch((err) => {
        message.error('请检查填写内容');
      });
  };
  // 呼叫中心
  const checkCallCenter = () => {
    let obj = formData;
    if (currentTask) {
      obj.name = currentTask.name;
      obj.sub_phone = currentTask.sub_phone;
      obj.crm_customers_id = currentTask.crm_main_id;
      obj.crm_user_id = currentTask.id;
      obj.yixiang = 0;
    } else {
      obj.name = tableData.rows[taskNum].name;
      obj.sub_phone = tableData.rows[taskNum].sub_phone;
      obj.crm_customers_id = tableData.rows[taskNum].crm_main_id;
      obj.crm_user_id = tableData.rows[taskNum].id;
    }
    obj.isCallCenter = true;
    if (!obj.content) {
      obj.content = '';
    }
    if (!obj.phone_status) {
      obj.phone_status = '';
    }
    if (!obj.man_ai_wish) {
      obj.man_ai_wish = '';
    }
    if (!obj.role_name) {
      obj.role_name = '';
    }

    General.addFollowLog(obj)
      .then((res) => {
        if (res.code == 200) {
          message.success(res.msg);
          form.resetFields();
        }
      })
      .catch((err) => {
        message.error('请检查填写内容');
      });
  };
  // 跟进记录
  const formChange = (changedValues, allValues) => {
    setFormData(allValues);
  };
  const modalCancel = () => {
    setShowModal(false);
  };
  // 是否关闭任务
  const showCancel = () => {
    if (taskStatus !== 4) {
      setShowModal(true);
    } else {
      cancelTask();
    }
  };
  // 完成任务
  const modalOk = () => {
    cancelTask();
  };
  // 设置当前任务着重色
  const setRowClassName = (record) => {
    if (clickBg) {
      if (currentTask && record.id == currentTask.id) {
        return 'nowTaskProgress';
      }
    } else {
      if (tableData.rows[taskNum] && record.id == tableData.rows[taskNum].id) {
        return 'nowTaskProgress';
      }
    }
  };
  return (
    <>
      {tableData.rows !== undefined && tableData.rows.length !== 0 && (
        <>
          {minORmax == 'max' && (
            <>
              <div className='task_modal_mask'></div>
              <div className='max_task_modal'>
                <Row>
                  <div
                    className='max_zoom_btn cursor'
                    onClick={() => {
                      setMinOrMax('min');
                    }}
                    css={{ lineHeight: '14px' }}
                  >
                    <img css={{ width: 14, height: 14 }} src='http://test.qiniu.phplijun.cn/beidou/min.png' alt='' />
                  </div>
                  <Col span={24} css={{ borderTopLeftRadius: 4, height: 48, padding: '14px 20px', background: '#F6F6F6' }}>
                    批量拨打任务
                  </Col>
                  <Col span={24} css={{ padding: '20px 20px 0 20px' }}>
                    <Row css={{ border: '1px solid #0f0f0f', borderRadius: 4 }}>
                      <Col span={20} css={{ padding: '14px 20px' }}>
                        <p>
                          进度：
                          <span css={{ fontSize: 15 }}>{taskStatus == 4 || taskStatus == 2 || taskStatus == 3 ? <b>{parseInt(taskNum) + 1}</b> : <b>{parseInt(taskNum)}</b>}</span>/
                          <span css={{ fontSize: 15 }}>
                            <b>{tableData.total}</b>
                          </span>
                        </p>
                        <p style={{ margin: 0 }}>
                          状态：{taskStatus == 1 && <span className='progress-leisure'>未开始</span>}
                          {taskStatus == 2 && <span className='progress-underway'>拨打中</span>}
                          {taskStatus == 3 && <span className='progress-leisure'>暂停</span>}
                          {taskStatus == 4 && <span className='progress-complete'>已完成</span>}
                        </p>
                      </Col>
                      <Col span={4} css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 14 }}>
                        {taskStatus == 1 && (
                          <div onClick={startTask} className='call-task-icon cursor'>
                            <img css={{ width: 30, height: 30 }} src='http://test.qiniu.phplijun.cn/beidou/start.png' alt='' />
                            开始任务
                          </div>
                        )}
                        {taskStatus == 2 && (
                          <div onClick={pauseTask} className='call-task-icon cursor'>
                            <img css={{ width: 30, height: 30 }} src='http://test.qiniu.phplijun.cn/beidou/pasue.png' alt='' />
                            暂停
                          </div>
                        )}
                        {taskStatus == 3 && (
                          <div
                            onClick={() => {
                              if (isConnect == 'hungUp') {
                                startTask();
                              } else {
                                message.error('当前话机通话还未结束，系统提示挂断成功后才可继续拨打！');
                              }
                            }}
                            className='call-task-icon cursor'
                          >
                            <img css={{ width: 30, height: 30 }} src='http://test.qiniu.phplijun.cn/beidou/continue.png' alt='' />
                            继续拨打
                          </div>
                        )}
                        {taskStatus !== 4 && (
                          <div onClick={showCancel} className='call-task-icon cursor'>
                            <img css={{ width: 30, height: 30 }} src='http://test.qiniu.phplijun.cn/beidou/cancel.png' alt='' />
                            取消
                          </div>
                        )}
                        {taskStatus == 4 && (
                          <Button onClick={showCancel} danger>
                            任务完成，关闭
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={17}>
                    <Row css={{ padding: 20 }}>
                      <Col span={24}>
                        <Table
                          rowClassName={setRowClassName}
                          scroll={{ x: 700, y: 500 }}
                          className='taskTable'
                          bordered
                          columns={columns}
                          dataSource={tableData.rows}
                          onRow={(record, index) => {
                            return {
                              onClick: (event) => {
                                setClickBg(true);
                                setCurrentTask(record);
                                form.resetFields();
                              }, // 点击行
                            };
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={7}>
                    <Row css={{ padding: 20 }}>
                      <Col span={24}>
                        <div css={{ background: '#f4f4f4', borderRadius: 4, padding: '18px 16px' }}>
                          <p>当前任务</p>
                          {taskStatus == 2 ? (
                            <>
                              <p css={{ margin: 0, display: 'flex', justifyContent: 'space-between' }}>
                                {/* <span>{taskNum == 0 ? tableData.rows[taskNum].name : tableData.rows[taskNum - 1] ? tableData.rows[taskNum - 1].name : tableData.rows[taskNum - 1].name}</span>
                                <span>
                                  {taskNum == 0 ? tableData.rows[taskNum].sub_phone : tableData.rows[taskNum - 1] ? tableData.rows[taskNum - 1].sub_phone : tableData.rows[taskNum - 1].sub_phone}
                                </span> */}
                                <span>{tableData.rows[taskNum].name}</span>
                                <span>{tableData.rows[taskNum].sub_phone}</span>
                                <span className='underway-tip'>拨打中...</span>
                              </p>
                            </>
                          ) : (
                            <p css={{ margin: 0 }}>
                              <span className='leisure-tip'>空闲中...</span>
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col span={24}>
                        {/* 线索管理 */}
                        {TaskCreateType == 'clueControl' && (
                          <>
                            {/* <p css={{ margin: 0 }}>线索ID：{currentTask ? currentTask.id : taskNum == 0 ? tableData.rows[taskNum].id : tableData.rows[taskNum - 1].id}</p>
                            <p css={{ margin: 0, fontSize: 14 }}>客户名称：{currentTask ? currentTask.name : taskNum == 0 ? tableData.rows[taskNum].name : tableData.rows[taskNum - 1].name}</p> */}
                            <p css={{ margin: 0 }}>线索ID：{currentTask ? currentTask.id : tableData.rows[taskNum].id}</p>
                            <p css={{ margin: 0, fontSize: 14 }}>客户名称：{currentTask ? currentTask.name : tableData.rows[taskNum].name}</p>

                            <Form form={form} name='basic' onValuesChange={formChange} layout='vertical'>
                              <Form.Item colon={false} label='联系人' name='name' style={{ display: 'none' }}>
                                <Input disabled />
                              </Form.Item>
                              <Form.Item colon={false} label='电话' name='sub_phone' style={{ display: 'none' }}>
                                <Input disabled />
                              </Form.Item>
                              <Form.Item label='号码状态' name='phone_status'>
                                <Select showSearch optionFilterProp='label' options={BaseInfo.phone_status} placeholder='请选择'></Select>
                              </Form.Item>
                              <Form.Item colon={false} label='联系人角色' name='role_name'>
                                <Select showSearch optionFilterProp='label' mode='multiple' options={BaseInfo.role_names} placeholder='请选择'></Select>
                              </Form.Item>

                              <Form.Item colon={false} label='销售状态' name='sale_rank'>
                                <Select showSearch optionFilterProp='label' options={BaseInfo.sale_ranks} placeholder='请选择'></Select>
                              </Form.Item>
                              {formData.sale_rank && formData.sale_rank == '6' && (
                                <Form.Item colon={false} label='输单原因' name='why_no_deal'>
                                  <Select showSearch optionFilterProp='label' options={BaseInfo.why_no_deals} placeholder='请选择'></Select>
                                </Form.Item>
                              )}
                              <Form.Item colon={false} label='备注' name='content'>
                                <Input />
                              </Form.Item>
                            </Form>
                          </>
                        )}
                        {/* 呼叫中心 */}
                        {TaskCreateType == 'callCenter' && (
                          <>
                            {/* <p css={{ margin: 0 }}>线索ID：{currentTask ? currentTask.id : taskNum == 0 ? tableData.rows[taskNum].id : tableData.rows[taskNum - 1].id}</p>
                            <p css={{ margin: 0, fontSize: 14 }}>客户名称：{currentTask ? currentTask.name : taskNum == 0 ? tableData.rows[taskNum].name : tableData.rows[taskNum - 1].name}</p> */}
                            <p css={{ margin: 0 }}>线索ID：{currentTask ? currentTask.id : tableData.rows[taskNum].id}</p>
                            <p css={{ margin: 0, fontSize: 14 }}>客户名称：{currentTask ? currentTask.name : tableData.rows[taskNum].name}</p>
                            <Form form={form} name='basic' onValuesChange={formChange} layout='vertical'>
                              <Form.Item colon={false} label='联系人' name='name' style={{ display: 'none' }}>
                                <Input disabled />
                              </Form.Item>
                              <Form.Item colon={false} label='联系人角色' name='role_name'>
                                <Select showSearch optionFilterProp='label' mode='multiple' options={BaseInfo.role_names} placeholder='请选择'></Select>
                              </Form.Item>
                              <Form.Item colon={false} label='电话' name='sub_phone' style={{ display: 'none' }}>
                                <Input disabled />
                              </Form.Item>
                              <Form.Item label='人工外呼意向度' name='man_ai_wish'>
                                <Select showSearch optionFilterProp='label' options={BaseInfo.man_ai_wish} placeholder='请选择'></Select>
                              </Form.Item>
                              <Form.Item label='号码状态' name='phone_status'>
                                <Select showSearch optionFilterProp='label' options={BaseInfo.phone_status} placeholder='请选择'></Select>
                              </Form.Item>
                              <Form.Item colon={false} label='跟进记录' name='content'>
                                <Input />
                              </Form.Item>
                            </Form>
                          </>
                        )}
                      </Col>
                      <Col span={24} css={{ display: 'flex', justifyContent: 'space-between', marginTop: 17 }}>
                        {taskStatus == 2 && (
                          <Button loading={nextLoading} onClick={callNext}>
                            挂断并拨打下一个
                          </Button>
                        )}
                        {taskStatus == 3 && isConnect !== 'hungUp' && isConnect !== '' && (
                          <Button loading={nextLoading} onClick={callNow}>
                            挂断当前电话
                          </Button>
                        )}
                        <Button onClick={saveFollowLog}>保存跟进记录</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </>
          )}
          {minORmax == 'min' && (
            <div className='min_task_modal'>
              <Row>
                <div
                  className='min_zoom_btn cursor'
                  onClick={() => {
                    setMinOrMax('max');
                  }}
                >
                  <img css={{ width: 30, height: 30 }} src='http://test.qiniu.phplijun.cn/beidou/max.png' alt='' />
                </div>
                <Col span={24}>
                  <Row>
                    <Col span={17}>
                      <p css={{ margin: 0 }}>
                        批量拨打任务进度：
                        <span css={{ fontSize: 15 }}>{taskStatus == 4 || taskStatus == 2 || taskStatus == 3 ? <b>{parseInt(taskNum) + 1}</b> : <b>{parseInt(taskNum)}</b>}</span>/
                        <span css={{ fontSize: 15 }}>
                          <b>{tableData.total}</b>
                        </span>
                      </p>
                      <p style={{ margin: 0 }}>
                        状态：{taskStatus == 1 && <span className='progress-leisure'>未开始</span>}
                        {taskStatus == 2 && <span className='progress-underway'>拨打中</span>}
                        {taskStatus == 3 && <span className='progress-leisure'>暂停</span>}
                        {taskStatus == 4 && <span className='progress-complete'>已完成</span>}
                      </p>
                    </Col>
                    <Col span={7} css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      {taskStatus == 1 && (
                        <div onClick={startTask} className='call-task-icon cursor'>
                          <img css={{ width: 30, height: 30 }} src='http://test.qiniu.phplijun.cn/beidou/start.png' alt='' />
                          开始任务
                        </div>
                      )}
                      {taskStatus == 2 && (
                        <div onClick={pauseTask} className='call-task-icon cursor'>
                          <img css={{ width: 30, height: 30 }} src='http://test.qiniu.phplijun.cn/beidou/pasue.png' alt='' />
                          暂停
                        </div>
                      )}
                      {taskStatus == 3 && (
                        <div
                          onClick={() => {
                            if (isConnect == 'hungUp') {
                              startTask();
                            } else {
                              message.error('正在加紧接收结果，请成功后继续拨打！');
                            }
                          }}
                          className='call-task-icon cursor'
                        >
                          <img css={{ width: 30, height: 30 }} src='http://test.qiniu.phplijun.cn/beidou/continue.png' alt='' />
                          继续拨打
                        </div>
                      )}

                      {taskStatus !== 4 && (
                        <div onClick={showCancel} className='call-task-icon cursor'>
                          <img css={{ width: 30, height: 30 }} src='http://test.qiniu.phplijun.cn/beidou/cancel.png' alt='' />
                          取消
                        </div>
                      )}
                      {taskStatus == 4 && (
                        <Button onClick={showCancel} danger loading={cancelLoading}>
                          任务完成，关闭
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
          <Modal zIndex={1015} visible={showModal} maskClosable={false} keyboard={false} closable={false} onOk={modalOk} onCancel={modalCancel}>
            <div>
              取消后未拨打的任务会同步取消。
              <br />
              <b>确定取消批量拨打任务？</b>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default Maximize;
