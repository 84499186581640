import axios from 'axios';

let zyb_URL = window.location.origin;
if (zyb_URL === 'http://localhost:3000') {
  zyb_URL = 'http://lijun.phplijun.cn';
  // zyb_URL = 'http://test.phplijun.cn';
  // zyb_URL = 'http://ghy.phplijun.cn';
  // zyb_URL = 'http://wyl.phplijun.cn';
  // zyb_URL = 'http://zyb.phplijun.cn';
  // zyb_URL = 'http://hotfix.phplijun.cn';

}

axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';
axios.defaults.xsrfCookieName = 'XSRF-TOKEN'; // default

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// axios.defaults.timeout = 30000;
axios.defaults.baseURL = zyb_URL;

// 封装get请求
export function Get(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// 封装post请求
export function Post(url, data = {}, config = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
